import { useEffect, useState } from 'react';

import { Container, Table, Panel, PanelGroup } from 'rsuite';
import ApiInstance from '../../services/api.service';
import Select from 'react-select';
import './collection-page.css';
import CellCollectionOwned from './collection.own';
const { Column, HeaderCell, Cell } = Table;


function CollectionPage() {

    const [sets, setSets] = useState([]);
    const [data, setData] = useState([]);
    const [collection, setCollection] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [packs, setPacks] = useState([]);

    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);

    function build(packs) {

        const sorted = packs.sort(function (a, b) {

            if (a.cyclePosition > b.cyclePosition) {
                return 1;
            }
            if (a.cyclePosition < b.cyclePosition) {
                return -1;
            }
            if (a.packPosition > b.packPosition) {
                return 1;
            }
            if (a.packPosition < b.packPosition) {
                return -1;
            }
            return 0;
        });
        const x = sorted.map(function (m) {
            const label = (m.cycleName !== m.packName) ? `${m.cycleName} - ${m.packPosition} - ${m.packName}` : m.packName;
            return { value: m.packCode, label, data: m };
        });
        setPacks(x);
        return;
    }

    function onChange(e) {
        setSelectedOption(e.value);
        setData(collection.filter(f => f.packCode === e.value));
    }

    const getData = () => {
        if (sortColumn && sortType) {
            return data.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    const CompactCell = props => <Cell {...props} style={{ padding: 4 }}  />;
    const CompactHeaderCell = props => <HeaderCell {...props} style={{ padding: 4 }} />;

    useEffect(() => {
        Promise.all([ApiInstance.getCollection().then(setCollection), ApiInstance.getPacks().then(build)]);
    }, []);

    useEffect(() => {
        if (packs.length > 1 && collection.length) {
            onChange({ value: packs[1].value });
        }
    }, [collection, packs]);

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    return (
        <Container style={{ height: '90vh', padding: '20px', fontSize: '12px' }}>

            <Select
                className='test'
                name="packs"
                options={packs}
                onChange={onChange}
                defaultValue={{ label: 'Core Set', value: 'core' }}
            />
            <Table
                name="data"
                style={{paddingTop:'2px'}}
                data={getData()}
                affixHeader={true}
                fillHeight={true}
                headerHeight={30}
                rowHeight={30}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                onRowClick={rowData => {
                    console.log(rowData);
                }}>
                <Column width={60} align="right" sortable>
                    <CompactHeaderCell>No</CompactHeaderCell>
                    <CompactCell dataKey="position" />
                </Column>
                <Column flexGrow={2} width={260} align="left" sortable>
                    <CompactHeaderCell>Card Name</CompactHeaderCell>
                    <CompactCell dataKey="strippedTitle" />
                </Column>
                <Column width={150}>
                    <CompactHeaderCell>Type</CompactHeaderCell>
                    <CompactCell dataKey="typeName" />
                </Column>
                <Column width={150}>
                    <CompactHeaderCell>Per Pack</CompactHeaderCell>
                    <CompactCell dataKey="quantity" />
                </Column>

                <Column width={150} align="left">
                    <CompactHeaderCell>Own</CompactHeaderCell>
                    <CellCollectionOwned dataKey="collectionCount" />
                </Column>


            </Table>



        </Container>
    );
}

export default CollectionPage;