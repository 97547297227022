function PackApiService({makeRequest, buildRequest}) {

    function getPacks() {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.get('api/packs'));
            });
    }

    return {
        getPacks
    };
};

export default PackApiService;