import React, { createContext, useContext } from 'react';

const configContext = createContext();
const url = process.env.NODE_ENV !== 'development' ? 'https://api-netrunner.breachie.com' : 'http://localhost:8003';
export const ProvideConfig = function({ children }) {
    return (
        <configContext.Provider value={ConfigService}>
            {children}
        </configContext.Provider>
    );
};

export const useConfig = function() {
    return useContext(configContext);
};

const ConfigService = function() {
    return {
        url
    };
}();

export default ConfigService;