import './button.css';

function CyberButton({  text, glitchText, tag, icon, ...props }) {

    return (
        <button className="cybr-btn" {...props}>{icon} {text}<span aria-hidden>_</span>
            <span aria-hidden className="cybr-btn__glitch">{icon}{glitchText || text}</span>
            <span aria-hidden className="cybr-btn__tag">{tag || 'R25'}</span>
        </button>
    );
}

export default CyberButton;