
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FlexboxGrid, Col } from 'rsuite';
import { SchemaModel, StringType } from 'schema-typed';

import logo from '../../assets/android-netrunner-logo.png';
import CyberButton from '../../components/button/button';
import { useAuth } from '../../services/auth.service';

import './login-page.css';

function LoginPage() {

    const navigate = useNavigate();
    const formRef = React.useRef();
    const [busy, setBusy] = useState(false);
    const [show, setShow] = React.useState(true);
    const [formValue, setFormValue] = useState({
        userAccount: '',
        userPassword: ''
    });

    const auth = useAuth();

    const model = SchemaModel({
        userAccount: StringType().isRequired('Enter your user account.'),
        userPassword: StringType().isRequired('Enter you password.')
    });

    useEffect(() => {
        if (auth.isAuthenticated()) {
            navigate('/');
        }
    }, []);

    const handleSubmit = function () {
        if (!formRef.current.check()) {
            return null;
        }
        setBusy(true);
        return auth.login(formValue.userAccount, formValue.userPassword, formValue.rememberMe || false)
            .then(function () {

                navigate('/');
                return;
            })
            .catch(function (e) {
                console.log(e)
                // if (e.status === 401) {
                //     setLoginError('Either account or password was entered incorrectly.');
                // } else {
                //     setLoginError('An error has occured, bad luck.');
                // }
                //TODO: add display error
            })
            .finally(function () {
                setBusy(false);
            });
    };

    return (
        <FlexboxGrid justify="space-around">
            <FlexboxGrid.Item as={Col} colspan={24} md={14} lg={10} >
                <Form
                    ref={formRef}
                    model={model}
                    className='loginForm'
                    onSubmit={handleSubmit}
                    onChange={setFormValue}
                    layout='horizontal' autoComplete="off" >
                    <div className='loginLogo'>
                        <img src={logo} alt='logo'></img>
                    </div>
                    <div className='loginFormGroup'>
                        <Form.Group controlId="userAccount">
                            <Form.ControlLabel className='loginFormLabel trajin-pro '>Email</Form.ControlLabel>
                            <Form.Control className='monkirta' autoComplete='off' size="sm" name="userAccount" errorPlacement='rightEnd' />
                        </Form.Group>
                    </div>
                    <div className='loginFormGroup'>
                        <Form.Group controlId="userPassword">
                            <Form.ControlLabel className='loginFormLabel trajin-pro '>Password</Form.ControlLabel>
                            <Form.Control className='monkirta' name="userPassword" size="sm" type="password" autoComplete="off" errorPlacement='topEnd' />
                        </Form.Group>
                    </div>
                    <div className='loginButton' style={{ display: 'flex', justifyContent: 'right', paddingRight: '7px' }}>
                        <CyberButton type="submit" text='login' glitchText='L gin.' disabled={busy}  ></CyberButton>
                    </div>
                </Form>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
}

export default LoginPage;