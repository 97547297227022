import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './../../services/auth.service';
import { } from './../../auth/'
import { Container, Header, Content, Footer, Form, ButtonToolbar, Button, Navbar, Nav, Panel, FlexboxGrid } from 'rsuite';

import { CollectionPage, DeckPage, DecklistPage, SetPage, AdminPage, SettingPage } from './../index';

function HomePage() {
    return (
        <Routes>
            <Route path='/' element={<div>Home Test</div>}></Route>
            <Route path='collection' element={<CollectionPage/>}  ></Route>
            <Route path='decks' element={<DeckPage/>}  ></Route>
            <Route path='decklists' element={<DecklistPage/>}  ></Route>
            <Route path='sets' element={<SetPage/>}  ></Route>
            <Route path='admin' element={<PrivateRoute groupRoles={['NETRUNNER_ADMIN']}><AdminPage/></PrivateRoute>}  ></Route>
            <Route path='settings' element={<SettingPage/>}  ></Route>
        </Routes>
    );
}

export default HomePage;