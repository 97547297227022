import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import find_truth from './assets/wallpapers/wallpaper_1920x1080_find_truth.jpg';
import weyland from './assets/wallpapers/wallpaper_1920x1080_weyland.jpg';
import jinteki from './assets/wallpapers/wallpaper_1920x1080_jinteki.jpg';
import maya from './assets/wallpapers/wallpaper_1920x1080_maya.jpg';
import nbn from './assets/wallpapers/wallpaper_1920x1080_nbn.jpg';
import psk from './assets/wallpapers/wallpaper_1920x1080_psk.jpg';
import skulljack from './assets/wallpapers/wallpaper_1920x1080_skulljack.jpg';

import { LoginPage, HomePage, FourZeroFourPage } from './pages';
import { ProviderAuth, PrivateRoute } from './services/auth.service';
import { Container, Header, Content, Footer, Form, ButtonToolbar, Button, Navbar, Nav, Panel, FlexboxGrid } from 'rsuite';
import MasterLayout from './layouts/master.layout';

import './app.css';
const backgroud = [skulljack, find_truth, weyland, jinteki, maya, nbn, psk];
const backgoundIndex = [5, 20, 40, 50, 60, 70, 80];

function App() {

    const value = Math.random() * 100;
    const closest = backgoundIndex.reduce(function (prev, curr) {
        return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    });
    const index = backgoundIndex.indexOf(closest);
    const selectedPicture = backgroud[index];

    const [windowDimension, setWindowDimension] = useState(null);
    const isMobile = windowDimension <= 800;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }
        setLoaded(true);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div id='app' style={{ backgroundSize: 'cover', backgroundPosition:'center', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${selectedPicture})` }} className='app noselect' >
            <ProviderAuth>
                <Suspense fallback={<div></div>}>
                    <Container>
                        <Content>
                            <Routes>
                                <Route path='/' element={<PrivateRoute><MasterLayout /></PrivateRoute>} >
                                    <Route path='*' element={<HomePage />} />
                                </Route>
                                <Route path='login' element={<LoginPage />} />
                                <Route path="*" element={<FourZeroFourPage />} />
                            </Routes>
                        </Content>
                    </Container>
                </Suspense>
            </ProviderAuth>
        </div>
    );

}

export default App;