import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CyberButton from '../button/button';
import { Container, Header, Content, Footer, Form, ButtonToolbar, Button, Navbar, Nav, Panel, FlexboxGrid } from 'rsuite';
import { FaCog, FaHome, FaSignOutAlt, FaUser } from 'react-icons/fa';

import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';

import { useAuth } from '../../services/auth.service';
import './header.css';

function HeaderNav() {

    const auth = useAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());

    useEffect(() => {
        setIsAuthenticated(() => auth.isAuthenticated());
    }, [auth.isAuthenticated()]);

    return (
        <Navbar appearance="subtle" className='cyber-banner-short' style={{backgroundColor:'purple'}}>
            <span className='navbar-brand'>
                Netrunner
            </span>
            {isAuthenticated ? <NavMenu /> : null}
        </Navbar>
    );

}

const NavMenu = () => {
    //useing routerdom instead of href to avoid page reload
    const auth = useAuth();
    const navigate = useNavigate();
    function select(eventKey) {
        console.log(eventKey)
        if (!eventKey) {
            return;
        }
        if (eventKey === 'logout') {
            auth.logout();
        } else {
            navigate(eventKey);
        }
    }

    return (
        <>
            <Nav onSelect={select} >
                <CyberButton icon={<HomeIcon/>} text="Home" glitchText="Collection" style={{color:'black', marginTop:'-50px'}} onClick={select.bind(null,'/')}></CyberButton>
                <CyberButton text="My Collection" glitchText="Collection" tag="z69" style={{color:'black'}} onClick={select.bind(null,'/collection')}></CyberButton>
                <CyberButton text="Decks" glitchText="Collection" tag="==D" style={{color:'black'}} onClick={select.bind(null,'/decks')}></CyberButton>
            </Nav>
            <Nav onSelect={select} pullRight>
                { auth.isInAnyRole(['NETRUNNER_ADMIN']) &&
                    <Nav.Item eventKey="/admin" icon={<CogIcon />}>Admin</Nav.Item>
                }
                <Nav.Menu className='settings' icon={<FaCog />} title='Settings'>
                    <CyberButton icon={<CogIcon />} text="Settings" onClick={select.bind(null,'/settings')} glitchText='Sett ngs.' />
                    <CyberButton icon={<FaSignOutAlt />} text="Logout" onClick={select.bind(null,'logout')} glitchText='L ogo t.' />
                </Nav.Menu>
            </Nav>
        </>
    );
};

export default HeaderNav;