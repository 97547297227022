import config from './config';
const EVENT_NAMESPACE = config.EVENT_NAMESPACE || 'netrunner';

const EVENTS = {
    LOGGED_IN: 'logged_in',
    LOGGING_IN: 'logging_in',
    FAILED_LOGIN: 'failed_login',
    LOGGED_OUT: 'logged_out',
    LOGGED_IN_AUTHORIZED: 'logged_in_authorized',
    HTTP_UNAUTHORIZED: 'http_unauthorized',
    COLLECTION: {
        LOADED:  'collection_loaded',
        UPDATED: 'collection_updated',
        CREATED: 'collection_created',
        DELETED: 'collection_deleted'
    },
    BROADCAST_EVENTS: {
        DISCONNECTED: 'listener_disconnected',
        MESSAGE: 'listener_message',
        CONNECTED: 'listener_connected',
        EVENT_NAMESPACE: 'netrunner.'
    },
    BROADCAST_MESSAGES: {
        COLLECTION: {
            UPDATED: `${EVENT_NAMESPACE.toLowerCase()}.colection.updated`,
            CREATED: `${EVENT_NAMESPACE.toLowerCase()}.colection.created`,
            DELETED: `${EVENT_NAMESPACE.toLowerCase()}.colection.deleted`,
            REFRESH: `${EVENT_NAMESPACE.toLowerCase()}.colection.refresh`
        }
    }
};

export default EVENTS;