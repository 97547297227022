import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import find_truth from '../assets/wallpapers/wallpaper_1920x1080_find_truth.jpg';
import weyland from '../assets/wallpapers/wallpaper_1920x1080_weyland.jpg';
import jinteki from '../assets/wallpapers/wallpaper_1920x1080_jinteki.jpg';
import maya from '../assets/wallpapers/wallpaper_1920x1080_maya.jpg';
import nbn from '../assets/wallpapers/wallpaper_1920x1080_nbn.jpg';
import psk from '../assets/wallpapers/wallpaper_1920x1080_psk.jpg';
import skulljack from '../assets/wallpapers/wallpaper_1920x1080_skulljack.jpg';

import HeaderNav from '../components/header/header';
import MobileNav from '../components/mobile-navbar/mobile.navbar';

import '../app.css';
const backgroud = [skulljack, find_truth, weyland, jinteki, maya, nbn, psk];
const backgoundIndex = [5, 20, 40, 50, 60, 70, 80];

function App() {

    const value = Math.random() * 100;
    const closest = backgoundIndex.reduce(function (prev, curr) {
        return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    });
    const index = backgoundIndex.indexOf(closest);
    const selectedPicture = backgroud[index];

    const [windowDimension, setWindowDimension] = useState(null);
    const isMobile = windowDimension <= 800;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }
        setLoaded(true);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ backgroundSize: 'cover', backgroundPosition:'center', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${selectedPicture})` }} className='app noselect' >
            {loaded && <>
                {!isMobile &&  <div style={{display:'flex',width:'100%', flex:1, flexDirection:'column'}}><HeaderNav /><Outlet /></div> }
                {isMobile && <div style={{display:'flex',width:'100%', flex:1}}><MobileNav /><div style={{width:'100%',overflow:'scroll'}}><Outlet /></div></div>}
            </>}
        </div>

    );

}

export default App;