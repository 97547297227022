/* eslint-disable no-invalid-this */

const EventbusService = function Eventbus() {

    const handersByEvent = {};
    function isFunction(func) {
        const obj = {};
        return func && obj.toString.call(func) === '[object Function]';
    }

    function check(eventNamed, funcToCall) {
        if (!eventNamed || typeof eventNamed !== 'string') {
            throw new Error('No Event string');
        }
        if (!funcToCall || !isFunction(funcToCall)) {
            throw new Error('Not a function');
        }
    }

    function on(eventNamed, funcToCall) {
        check(eventNamed, funcToCall);
        const targetEventStack = handersByEvent[eventNamed] = handersByEvent[eventNamed] || [];
        targetEventStack.push(funcToCall);
    }

    function isNot(itemToCompare) {
        return function(item) {
            return item !== itemToCompare;
        };
    }

    function off(toRemove) {
        for (const event in handersByEvent) {
            handersByEvent[event] = handersByEvent[event].filter(isNot(toRemove));
        }
    }

    function once(eventNamed, funcToCall) {
        check(eventNamed, funcToCall);

        function one() {
            off(one);
            funcToCall.apply(this, arguments);
        }

        on(eventNamed, one);
    }

    function emit(eventNamed, eventData) {
        if (handersByEvent[eventNamed]) {
            handersByEvent[eventNamed].forEach(function(listener) {
                Promise.resolve().then(function() {
                    listener(eventData);
                });
            });
        }
    }

    return {
        on,
        once,
        off,
        emit
    };
}();


export default EventbusService;