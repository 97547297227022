import { useState } from 'react';
import { Table } from 'rsuite';
import ApiInstance from './../../services/api.service';
const { Column, HeaderCell, Cell } = Table;

function CellCollectionOwned(props) {
    const [cellValue, setCellValue] = useState(props.rowData[props.dataKey]);
    const rowData = props.rowData;
    const increaseClicked = async () => {
        console.log('increaseClicked', rowData)
        await ApiInstance.updateCollectionItemCount(rowData.code, cellValue + 1).then(function(res) {
            setCellValue(res.totalCount);

        });
    };
    
    const decreasedClicked = async () => {
        console.log('decreasedClicked', rowData)
        await ApiInstance.updateCollectionItemCount(rowData.code, rowData.collectionCount - 1);
    };
    return (
        <Cell {...props}>
            <button onClick={() => decreasedClicked()}>-</button>
            <span>{cellValue}</span>
            <button onClick={() => increaseClicked()}>+</button>
        </Cell>
    );
}

export default CellCollectionOwned;