import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';

import './assets/fonts/trajan-pro/Trajan Pro.ttf';
import './assets/fonts/monkirta/monkirta.ttf';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
// called before any tests are run

root.render(<BrowserRouter><App /></BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
