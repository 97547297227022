function CollectionApiService({makeRequest, buildRequest}) {

    function getCollection() {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.get('api/cards'));
            });
    }

    function updateCollectionItemCount(cardId, count) {
        return buildRequest()
            .then(function(req) {
                return makeRequest(req.post(`api/collection/card/${cardId}/${count}`));
            });
    }

    return {
        getCollection,
        updateCollectionItemCount
    };
}

export default CollectionApiService;