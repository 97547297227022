import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import { Container, Header, Content, Footer, Form, ButtonToolbar, Button, Navbar, Nav, Panel, FlexboxGrid } from 'rsuite';

function DeckPage() {
    return (
        <Container>
            Deck
        </Container>
    );
}

export default DeckPage;