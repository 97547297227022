import { useState, useEffect, forwardRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { Sidenav, Nav } from 'rsuite';

import GearIcon from '@rsuite/icons/Gear';
import { FaSignOutAlt, FaUser } from 'react-icons/fa';
import HomeIcon from '@rsuite/icons/legacy/Home';
import CyberButton from '../button/button';
import './mobile.navbar.css';

function MobileNavBar() {

    const navigate = useNavigate();

    const [expanded, setExpanded] = useState(false);
    const [activeKey, setActiveKey] = useState('1');

    function getPageName() {
        const pathnames = location.pathname.split('/');
        const key = pathnames[pathnames.length - 1];
        setActiveKey(key);
    }

    function getUserName() {
    }

    function logout() {
    }

    function getLogo() {
        return (
            <div className='' style={{ zIndex: 100002, width: '80px', height:'40px' }}>
                <div className='d-lg-block h-12 w-auto'>
                    <div className='logo'>

                        <div className='navbar-brand'></div>
                    </div>
                </div>
            </div>
        );
    }

    function select(eventKey) {
        console.log(eventKey);
        if (!eventKey) {
            return;
        }
        if (eventKey === 'logout') {
            auth.logout();
        } else {
            navigate(eventKey);
        }
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        getPageName();
    }, [location.pathname]);

    return (
        <div className='navigation-bar' style={{width: '50px' }}>
            <Sidenav expanded={expanded} appearance='inverse' style={{height:'100%'}} className='cyber-banner'>
                <Sidenav.Header>
                    {expanded &&
                        <span style={{ zIndex: 1, width: '100%', height:'50px', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                            <span style={{marginLeft: '30px', paddingTop: '20px', height:'50px', display:'flex', flex:1, alignItems:'center', fontSize:'large', fontWeight:'bold', color:'black'}}>Netrunner</span>
                        </span>
                    }
                    {
                        !expanded &&
                       <span style={{color:'black'}}>N</span>
                    }
                </Sidenav.Header>
                <Sidenav.Toggle onToggle={expanded => setExpanded(expanded)} className='expander' />
                <Sidenav.Body className='sidenav'>
                    <Nav activeKey={activeKey} className='sidenav'>
                        {expanded &&
                        <div className="expanded-buttons">
                            <CyberButton icon={<HomeIcon/>} text="Home" glitchText="Collection" style={{color:'black', marginBottom:5}} onClick={select.bind(null,'/')}></CyberButton>
                            <CyberButton text="My Collection" glitchText="Collection" tag="z69" style={{color:'black', marginBottom:5}} onClick={select.bind(null,'/collection')}></CyberButton>
                            <CyberButton text="Decks" glitchText="Collection" tag="==D" style={{color:'black', marginBottom:5}} onClick={select.bind(null,'/decks')}></CyberButton>
                        </div>
                        }
                    </Nav>
                    <Nav>
                        <Nav.Menu eventKey="6" title="Settings" icon={<GearIcon/>} id='settings' placement="rightEnd">
                            <Nav.Item key='Logout' eventKey='logout' icon={<FaSignOutAlt />}>Logout</Nav.Item>
                            <Nav.Item key='usename' eventKey='username' icon={<FaUser />}>{getUserName()}</Nav.Item>
                            <Nav.Item divider />
                            <div className='versionInfo'>
                                <b>Web Build Information:</b><br />
                                {'Version: '}<br />
                                {'Build: '}
                            </div>
                            <Nav.Item divider />
                            <div className='versionInfo'>
                                <b>API Build Information:</b><br />
                                <b>TBC</b>
                            </div>
                        </Nav.Menu>
                    </Nav>
                </Sidenav.Body>

            </Sidenav>
        </div>
    );

}

export default MobileNavBar;
