import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import { Container, Header, Content, Footer, Form, ButtonToolbar, Button, Navbar, Nav, Panel, FlexboxGrid } from 'rsuite';

function DecklistPage() {
    return (
        <Container>
            Deck List
        </Container>
    );
}

export default DecklistPage;